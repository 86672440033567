import request from '@/utils/request'

// 查询对外开放资源列表
export function listResource (query) {
  return request({
    url: '/openapi/resource/list',
    method: 'get',
    params: query
  })
}

// 查询对外开放资源详细
export function getResource (id) {
  return request({
    url: '/openapi/resource/' + id,
    method: 'get'
  })
}

// 新增对外开放资源
export function addResource (data) {
  return request({
    url: '/openapi/resource',
    method: 'post',
    data: data
  })
}

// 修改对外开放资源
export function updateResource (data) {
  return request({
    url: '/openapi/resource',
    method: 'put',
    data: data
  })
}

// 删除对外开放资源
export function delResource (id) {
  return request({
    url: '/openapi/resource/' + id,
    method: 'delete'
  })
}

// 导出对外开放资源
export function exportResource (query) {
  return request({
    url: '/openapi/resource/export',
    method: 'get',
    params: query
  })
}
